import * as React from "react";
import {useState, useEffect} from "react";
import {graphql, PageProps} from "gatsby";
import Cookies from "universal-cookie";

import CleanDataParser from "../helpers/editorjs-parser";
import Layout from "../components/layout";
import Section from "../components/section";
import TemplateHead from "../components/template-head";
import Hero from "../components/hero";

import "../styles/page-services.scss";
const cookies = new Cookies();

type DataProps = {
  pdfPageData: any,
}

export default function PriceList({data: {pdfPageData}}: PageProps<DataProps>) {
  const [standardisedPdf, setStandardisedPdf] = useState("");
  const [regionalStandardisedPdf, setRegionalStandardisedPdf] = useState(false);
  const cookieHub = cookies.get("fenix4hub");

  console.log(cookieHub)
  console.log(pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub}))

  useEffect(() => {
    setStandardisedPdf(cookieHub !== undefined && pdfPageData?.regionalPricePDF && pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub}).length>0 && pdfPageData?.regionalPricePDF && pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub})[0].hubSlug ?  pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub})[0]?.PDF_HubStandardisedPriceList.url : pdfPageData?.PDF_StandardisedPriceList?.url);
    setRegionalStandardisedPdf(cookieHub !== undefined && pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub}).length>0 && pdfPageData?.regionalPricePDF && pdfPageData?.regionalPricePDF.filter(function (el) {return el.hubSlug === cookieHub})[0].hubTitle )
  }, []);

  const pageFullText = (
    <div>
      {pdfPageData.fullContent?.data?.fullContent ? (
        JSON.parse(pdfPageData.fullContent?.data?.fullContent).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );
  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--pdf pl-4 pr-4 bg-white"
        contentClassName="hero-content hero-content--pdf"
      >
        <div className="row no-gutters hero-content__heading align-items-top">
          <div className="col-12 d-flex flex-column justify-content-center">
            <div className="hero-content__wrapper mx-auto text-left text-deepblue">
              {pdfPageData.title && <h1 className="hero-content__title text-center">{regionalStandardisedPdf ? regionalStandardisedPdf + " region funeral prices" : pdfPageData.title}</h1>}
              {pdfPageData.fullContent?.data?.fullContent && <div className="hero-content__subtitle">
                <div className="mb-3">{pageFullText} <div className="pt-1">Please be aware that regional pricing is in place.</div></div>
              </div>}
              <div className="d-flex justify-content-center mb-4 mt-3">
                <a href="tel:+448081649040">
                  <button type="button" className="btn btn-blue btn-blue--phone btn--shadow mr-0"><span>Call us</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <Section
        sectionClass={
          "bg-lightgrey pl-4 pr-4 pt-5 pb-2 section-container section-container--pdf"
        }
      >
        <div className="block-wrapper">
          <div className="row price-list price-list--row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12 price-list__col mb-4 mt-4">
              {pdfPageData.title_StandardisedPriceList && <div className="price-list__title text-center pb-3 mx-auto">
                <h2 dangerouslySetInnerHTML={{__html: pdfPageData.title_StandardisedPriceList}}></h2>
              </div>}
              {pdfPageData.PDF_StandardisedPriceList.url && <div className="price-list__img">
                <a
                  href={standardisedPdf.replace("https://nextpwcms.s3.eu-north-1.amazonaws.com/", "https://fenixfuneral.co.uk/pdf/")}
                  target="_blank">
                  <img className="w-100" src={pdfPageData.thumb_StandardisedPriceList.url}
                       alt={pdfPageData.title_StandardisedPriceList}/>
                </a>
              </div>}
            </div>
            <div className="col-lg-4 col-sm-6 col-12 price-list__col mb-4 mt-4">
              {pdfPageData.title_AdditionalOptionsPriceList &&
                <div className="price-list__title text-center pb-3 mx-auto">
                  <h2 dangerouslySetInnerHTML={{__html: pdfPageData.title_AdditionalOptionsPriceList}}></h2>
                </div>}
              {pdfPageData.PDF_AdditionalOptionsPriceList.url && <div className="price-list__img">
                <a
                  href={pdfPageData?.PDF_AdditionalOptionsPriceList?.url.replace("https://nextpwcms.s3.eu-north-1.amazonaws.com/", "https://fenixfuneral.co.uk/pdf/")}
                  target="_blank">
                  <img className="w-100" src={pdfPageData.thumb_AdditionalOptionsPriceList.url}
                       alt={pdfPageData.title_AdditionalOptionsPriceList}/>
                </a>
              </div>}
            </div>
            <div className="col-lg-4 col-sm-6 col-12 price-list__col mb-4 mt-4">
              {pdfPageData.title_CrematoriumPriceList && <div className="price-list__title text-center pb-3 mx-auto">
                <h2 dangerouslySetInnerHTML={{__html: pdfPageData.title_CrematoriumPriceList}}></h2>
              </div>}
              {pdfPageData.PDF_CrematoriumPriceList.url && <div className="price-list__img">
                <a
                  href={pdfPageData?.PDF_CrematoriumPriceList?.url.replace("https://nextpwcms.s3.eu-north-1.amazonaws.com/", "https://fenixfuneral.co.uk/pdf/")}
                  target="_blank">
                  <img className="w-100" src={pdfPageData.thumb_CrematoriumPriceList.url}
                       alt={pdfPageData.title_CrematoriumPriceList}/>
                </a>
              </div>}
            </div>
            <div className="col-lg-4 col-sm-6 col-12 price-list__col mb-4 mt-4">
              {pdfPageData.title_TermsOfBusiness && <div className="price-list__title text-center pb-3 mx-auto">
                <h2 dangerouslySetInnerHTML={{__html: pdfPageData.title_TermsOfBusiness}}></h2>
              </div>}
              {pdfPageData.PDF_TermsOfBusiness.url && <div className="price-list__img">
                <a
                  href={pdfPageData?.PDF_TermsOfBusiness?.url.replace("https://nextpwcms.s3.eu-north-1.amazonaws.com/", "https://fenixfuneral.co.uk/pdf/")}
                  target="_blank">
                  <img className="w-100" src={pdfPageData.thumb_TermsOfBusiness.url}
                       alt={pdfPageData.title_TermsOfBusiness}/>
                </a>
              </div>}
            </div>
            <div className="col-lg-4 col-sm-6 col-12 price-list__col mb-4 mt-4">
              {pdfPageData.title_DisclosureOfInterests && <div className="price-list__title text-center pb-3 mx-auto">
                <h2 dangerouslySetInnerHTML={{__html: pdfPageData.title_DisclosureOfInterests}}></h2>
              </div>}
              {pdfPageData.PDF_DisclosureOfInterests && <div className="price-list__img">
                <a
                  href={pdfPageData?.PDF_DisclosureOfInterests?.url.replace("https://nextpwcms.s3.eu-north-1.amazonaws.com/", "https://fenixfuneral.co.uk/pdf/")}
                  target="_blank">
                  <img className="w-100" src={pdfPageData.thumb_DisclosureOfInterests.url}
                       alt={pdfPageData.title_DisclosureOfInterests}/>
                </a>
              </div>}
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Standardised Price List - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/standardised-price-list/"
      alternate="https://fenixfuneral.co.uk/standardised-price-list/"
      canonical="https://fenixfuneral.co.uk/standardised-price-list/"
      og={{
        url: "https://fenixfuneral.co.uk/standardised-price-list/",
        title: "Standardised Price List - Fenix Funeral Directors",
      }}
    />
  );
}

export const pageQuery = graphql`
  query {
    pdfPageData: strapiStandardisedPricing {
      strapi_id
      title
      fullContent {
        data {
          fullContent
        }
      }
      title_StandardisedPriceList
      thumb_StandardisedPriceList {
        url
      }
      PDF_StandardisedPriceList {
        url
      }
      regionalPricePDF {
        hubSlug
        hubTitle
        PDF_HubStandardisedPriceList {
          url
        }
      }
      title_AdditionalOptionsPriceList
      thumb_AdditionalOptionsPriceList {
        url
      }
      PDF_AdditionalOptionsPriceList {
        url
      }
      title_CrematoriumPriceList
      thumb_CrematoriumPriceList {
        url
      }
      PDF_CrematoriumPriceList {
        url
      }
      title_DisclosureOfInterests
      thumb_DisclosureOfInterests {
        url
      }
      PDF_DisclosureOfInterests {
        url
      }
      title_TermsOfBusiness
      thumb_TermsOfBusiness {
        url
      }
      PDF_TermsOfBusiness {
        url
      }
    }
  }
`;